import { downloadBlob } from "./downloadBlob";

export const blobToCsv = async (blob: Blob, filename: string) => {
  const textData = await blob.text();
  const cleanData = JSON.parse(textData);

  const formattedBlob = new Blob(["\ufeff" + cleanData], {
    type: "text/csv;charset=utf-8;",
  });

  const url = window.URL.createObjectURL(formattedBlob);
  const fileName = `${filename}.csv`;

  downloadBlob(url, fileName);
};
