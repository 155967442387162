import { useRef, useEffect } from "react";

import * as d3 from "d3";

type DataPoint = { name: string; value: number };

interface ArcChartProps {
  data: DataPoint[];
  width?: number;
}

export const arcChartColors = [
  "var(--blue800)",
  "var(--blue300)",
  "var(--purple800)",
  "var(--purple300)",
  "var(--green800)",
  "var(--green300)",
  "var(--yellow600)",
  "var(--yellow300)",
  "var(--orange600)",
  "var(--orange400)",
  "var(--red700)",
  "var(--red400)",
  "var(--slate800)",
  "var(--slate400)",
  "var(--slate900)",
];

const ArcChart = ({ data, width = 270 }: ArcChartProps) => {
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!ref.current || !data || data.length === 0) return;

    const height = Math.min(width, 500);
    const radius = Math.min(width, height) / 2;

    const arc = d3
      .arc<d3.PieArcDatum<DataPoint>>()
      .innerRadius(radius * 0.82)
      .outerRadius(radius - 1);

    const pie = d3
      .pie<DataPoint>()
      .padAngle(0)
      .sort(null)
      .value((d) => d.value);

    const color = d3
      .scaleOrdinal<string, string>()
      .domain(data.map((d) => d.name))
      .range(
        arcChartColors && arcChartColors.length >= data.length
          ? arcChartColors
          : d3
              .quantize(
                (t) => d3.interpolateSpectral(t * 0.8 + 0.1),
                data.length
              )
              .reverse()
      );

    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto;");

    svg.selectAll("g").remove();

    svg
      .append("g")
      .selectAll("path")
      .data(pie(data))
      .join("path")
      .attr("fill", (d) => color(d.data.name))
      .attr("d", arc);

    const centerText = svg.append("g").attr("text-anchor", "middle");

    const today = new Date();
    const formattedDate = today.toLocaleDateString("es-AR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    centerText
      .append("text")
      .attr("y", -12)
      .attr("font-size", 14)
      .attr("font-weight", "bold")
      .text(formattedDate);

    centerText
      .append("text")
      .attr("y", 10)
      .attr("font-size", 12)
      .attr("font-weight", "normal")
      .attr("fill", "var(--slate800)")
      .text("Valores sobre el total ");

    centerText
      .append("text")
      .attr("y", 26)
      .attr("font-size", 12)
      .attr("font-weight", "normal")
      .attr("fill", "var(--slate800)")
      .text("de la cartera");
  }, [data, width]);

  return <svg ref={ref} />;
};

export default ArcChart;
