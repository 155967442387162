import { Cryptos } from "constants/cryptos";
import { DateRange } from "constants/dateRange";

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import LineChart from "components/common/LineChart";
import LoadingSpinner from "components/common/LoadingSpinner";
import { CryptoHistoricPrice } from "interfaces/api-responses";
import px2rem from "utils/px2rem";
import { isEmpty } from "lodash";
import { ThemeVariants } from "interfaces/theme";

interface CryptoChartProps {
  ticker: Cryptos;
  setPriceToDisplay: (value?: number) => void;
  setDateToDisplay: (value?: string) => void;
}

const CryptoChart: React.FC<CryptoChartProps> = ({
  ticker,
  setDateToDisplay,
  setPriceToDisplay,
}) => {
  const [chartData, setChartData] = useState<CryptoHistoricPrice[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<DateRange>(
    DateRange.ONE_DAY
  );

  const timeOptions = [
    { label: "1H", value: DateRange.ONE_HOUR },
    { label: "1D", value: DateRange.ONE_DAY },
    { label: "1S", value: DateRange.ONE_WEEK },
    { label: "1M", value: DateRange.ONE_MONTH },
    { label: "1A", value: DateRange.ONE_YEAR },
  ];

  const getChartData = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get<CryptoHistoricPrice[]>(
        `${cryptoService.historicPrices}?ticker=${ticker}&dateRange=${selectedTime}`
      );
      setChartData(data);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChartData();
    setDateToDisplay(undefined);
    setPriceToDisplay(undefined);
  }, [selectedTime]);

  const onChangeCrosshair = (v?: { time: number; value: number }) => {
    if (!v) {
      setPriceToDisplay(undefined);
      setDateToDisplay(undefined);
      return;
    }
    const date = new Date(v.time);
    setPriceToDisplay(v.value);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    if (
      selectedTime === DateRange.ONE_DAY ||
      selectedTime === DateRange.ONE_HOUR ||
      selectedTime === DateRange.ONE_WEEK
    ) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      setDateToDisplay(`${day}/${month} ${hours}:${minutes}`);

      return;
    }

    setDateToDisplay(`${day}/${month}/${year}`);
  };

  if (isLoading)
    return (
      <div>
        <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(195.53)} />
      </div>
    );

  if (error || isEmpty(chartData) || !chartData) return null;

  const mappedChartData = chartData.map(({ datetime, price }) => ({
    time: Date.parse(datetime),
    value: price,
  }));

  return (
    <div>
      <LineChart
        chartData={mappedChartData}
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        onChangeTime={setSelectedTime}
        onChangeCrosshair={onChangeCrosshair}
        ticker={ticker}
      />
    </div>
  );
};

export default CryptoChart;
