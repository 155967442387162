import {
  LINE_CHART_TIME_OPTIONS,
  PORTFOLIO_CHART_PERIOD,
} from "constants/portfolio";

import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import { usePortfolioHistoryQuery } from "apis/portfolio/queries/usePortfolioHistory";
import NewLineChart from "components/common/LineChart/NewLineChart";
import { Spinner } from "components/common/spinner";
import { usePortfolio } from "context/PortfolioProvider";
import dayjs from "dayjs";
import { PortfolioHistoryData } from "interfaces/portfolio";
import { ThemeVariants } from "interfaces/theme";
import type { ChartDataPoint } from "interfaces/charts";

import styles from "./styles.module.scss";

interface ChartSectionProps {
  selectedTime: number;
  handleOnChangeTime: (date: number) => void;
  onChangePortfolio: (dataPoint?: ChartDataPoint) => void;
  isPerformance?: boolean;
  variant?: ThemeVariants;
}

const ChartSection = ({
  selectedTime,
  handleOnChangeTime,
  onChangePortfolio,
  isPerformance = false,
  variant = ThemeVariants.Capital,
}: ChartSectionProps) => {
  const { selectedCurrency, selectedPeriod } = usePortfolio();
  const { data: lastDay, isLoading: isBalanceLoading } = useBalanceQuery({
    currency: selectedCurrency,
    period: selectedPeriod,
  });

  const color =
    variant === ThemeVariants.Capital ? "var(--blue800)" : "var(--purple800)";

  const {
    data: portfolioHistory,
    isLoading,
    isError,
  } = usePortfolioHistoryQuery(
    selectedCurrency,
    PORTFOLIO_CHART_PERIOD[selectedTime]
  );

  const chartData = portfolioHistory?.data.map(
    ({ time, balance, variation }: PortfolioHistoryData) => {
      return {
        date: time,
        value: balance,
        variation: variation,
      };
    }
  );

  if (lastDay && chartData) {
    const todayBalance = {
      date: dayjs().format("YYYY-MM-DD"),
      value: lastDay.totalBalance,
      variation: {
        amount: lastDay.variation.amount,
        percentage: lastDay.variation.percentage,
      },
    };

    chartData.push(todayBalance);
  }

  if (isLoading || isError || !chartData || isBalanceLoading)
    return (
      <div className={styles.chartLineLoaderContainer}>
        <Spinner />
      </div>
    );

  return (
    <div className={styles.chartLineContainer}>
      <NewLineChart
        isPerformance={isPerformance}
        chartData={chartData}
        onChangeTime={handleOnChangeTime}
        color={color}
        selectedTime={selectedTime}
        timeOptions={LINE_CHART_TIME_OPTIONS}
        onCrosshairChange={onChangePortfolio}
        currency={selectedCurrency}
        variant={variant}
      />
    </div>
  );
};

export default ChartSection;
