interface RiskFireProps {
  width?: number;
  height?: number;
}

export const RiskFire = ({ width = 13, height = 16 }: RiskFireProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.05232 7.26316C3.47109 7.26316 3.8727 7.0968 4.16881 6.80069C4.46492 6.50458 4.63127 6.10297 4.63127 5.68421C4.63127 4.81263 4.31548 4.42105 3.99969 3.78947C3.32264 2.436 3.85822 1.22905 5.26285 0C5.57864 1.57895 6.52601 3.09474 7.78917 4.10526C9.05232 5.11579 9.6839 6.31579 9.6839 7.57895C9.6839 8.15953 9.56955 8.73442 9.34737 9.27081C9.12519 9.8072 8.79954 10.2946 8.38901 10.7051C7.97847 11.1156 7.4911 11.4413 6.95471 11.6635C6.41833 11.8856 5.84343 12 5.26285 12C4.68227 12 4.10737 11.8856 3.57099 11.6635C3.0346 11.4413 2.54723 11.1156 2.13669 10.7051C1.72616 10.2946 1.40051 9.8072 1.17833 9.27081C0.956151 8.73442 0.841797 8.15953 0.841797 7.57895C0.841797 6.85074 1.11527 6.1301 1.47338 5.68421C1.47338 6.10297 1.63973 6.50458 1.93584 6.80069C2.23195 7.0968 2.63356 7.26316 3.05232 7.26316Z"
      fill="url(#paint0_linear_21212_138096)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_21212_138096"
        x1="5.26285"
        y1="2.65263"
        x2="5.26285"
        y2="12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--blue900)" />
        <stop offset="1" stopColor="var(--blue800)" />
      </linearGradient>
    </defs>
  </svg>
);
