import Variation from "components/common/CurrentVariation";
import { useAppState } from "context/AppStateProvider";
import { ThemeVariants } from "interfaces/theme";
import AvailableLabel from "components/common/AvailableLabel";
import type { CocosFund } from "components/page-capital-funds/helpers";
import { FundsTotalHolding } from "interfaces/fci";

import FundHeading from "../../components/FundHeading";

import styles from "./styles.module.scss";

interface FundsHeaderProps {
  fund: CocosFund;
  holding: FundsTotalHolding;
}

const FundsHeader = ({ fund, holding }: FundsHeaderProps) => {
  const { isBalanceHidden } = useAppState();
  const { name, ticker, currency } = fund;

  return (
    <div className={styles.fundsHeaderWrapper}>
      <FundHeading name={name} ticker={ticker} />
      <AvailableLabel
        currency={currency}
        available={holding.value}
        variant={ThemeVariants.Capital}
      />
      <Variation
        currency={currency}
        value={holding.lastProfit.percentage}
        amount={holding.lastProfit.amount}
        hideBalances={isBalanceHidden}
        text="último rendimiento"
      />
    </div>
  );
};

export default FundsHeader;
