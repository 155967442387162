import { useState, ReactNode } from "react";

import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import USFlag from "assets/icons/USFlag";
import ARGFlag from "assets/icons/ARGFlag";
import { Info } from "lucide-react";
import Currency from "components/common/Text/Currency";
import { getCurrencySymbol } from "utils";

import { RiskBottomsheet } from "./RiskBottomsheet";
import { RiskFireIcons } from "./RiskFireIcons";

import styles from "./styles.module.scss";

interface InfoBoxProps {
  currency: Currencies;
}

interface InfoRowProps {
  title: string;
  info: string;
}

const InfoRow = ({ title, info }: InfoRowProps) => (
  <div className={styles.infoBoxRow}>
    <Text variant={TextVariants.RegularText} color="var(--slate800)">
      {title}
    </Text>
    <Text variant={TextVariants.SemiboldText} color="var(--slate900)">
      {info}
    </Text>
  </div>
);

export const InfoBox = ({ currency }: InfoBoxProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getCurrencyInfo = (currency: Currencies): ReactNode => {
    const Icon = currency === Currencies.ARS ? ARGFlag : USFlag;
    const IconText = getCurrencySymbol(currency, true);

    return (
      <div className={styles.infoBoxRowCurrency}>
        <Icon size={20} />
        <Text variant={TextVariants.SemiboldText} color="var(--slate900)">
          {IconText}
        </Text>
      </div>
    );
  };

  const onInfoClik = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.infoBoxWrapper}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate800)"
        className={styles.infoHeading}
      >
        Información
      </Heading>
      <div className={styles.infoBoxContainer}>
        <InfoRow title="Inicio" info="12/11/25" />
        <div className={styles.infoBoxRow}>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Patrimonio
          </Text>
          <Currency
            amount={123123123}
            ticker={currency}
            color="var(--slate900)"
          />
        </div>
        <InfoRow title="Rescate" info="24hs" />
        <div className={styles.infoBoxRow}>
          <Text
            variant={TextVariants.RegularText}
            color="var(--slate800)"
            className={styles.riskIconText}
          >
            Volatilidad{" "}
            <Info size={16} onClick={onInfoClik} className={styles.infoIcon} />
          </Text>
          <RiskFireIcons risk={1} />
        </div>
        <div className={styles.infoBoxRow}>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Moneda
          </Text>
          {getCurrencyInfo(currency)}
        </div>
      </div>
      <RiskBottomsheet isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};
