import Text, { TextVariants } from "components/common/Text";
import Divider from "components/common/Divider";
import StatBlock from "components/common/StatBlock";
import { formatPercentage } from "utils";

import styles from "./styles.module.scss";

interface AnnualReturnsProps {
  tna: number;
}

const AnnualReturns = ({ tna }: AnnualReturnsProps) => {
  const earningsData = [
    {
      name: "Por día",
      value: formatPercentage(32),
    },
    {
      name: "Por mes",
      value: formatPercentage(49),
    },
    {
      name: "Por año",
      value: formatPercentage(324),
    },
  ];

  const annualTna = `≈${formatPercentage(tna, false)}`;

  return (
    <div className={styles.anualRateContainer}>
      <Text
        variant={TextVariants.SemiboldTextXL}
        color="var(--green800)"
        className={styles.anualRateNumber}
      >
        {annualTna}
      </Text>
      <Text variant={TextVariants.SemiboldTextS} color="var(--slate800)">
        Rendimiento anual estimado
      </Text>
      <Divider className={styles.earningsDivider} />
      <StatBlock valueColor="var(--green800)" statBlockData={earningsData} />
    </div>
  );
};

export default AnnualReturns;
