import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { BookUser, X } from "lucide-react";
import FilledButton from "components/common/FilledButton";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";
import Calendar from "components/common/Calendar";
import API from "apis";
import Toast, { ToastVariants } from "components/common/Toast";
import { portfolioService } from "apis/services";
import px2rem from "utils/px2rem";
import dayjs from "dayjs";
import { blobToCsv } from "utils/blobToCsv";

import styles from "./styles.module.scss";

const DownloadPortfolioReport = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const onCalendarChange = (dateString: Date) => {
    setSelectedDate(dateString);
  };

  const onCrossIconClick = () => {
    dispatch(closeRightModal());
  };

  const onDownloadReportClick = async () => {
    setIsLoading(true);
    setIsDownloadError(false);

    try {
      const date = dayjs(selectedDate).format("YYYYMMDD");

      const { data } = await API.get<Blob>(
        `${portfolioService.reports}?type=CSV&date=${date}`,
        {
          responseType: "blob",
        }
      );

      blobToCsv(data, `portfolio_report_${date}`);
    } catch (err) {
      setIsDownloadError(true);
      console.error("Error Downloading Report: ", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.downloadReportsWrapper}>
      <div className={styles.downloadReportsHeader}>
        <Text color="var(--slate800)">Descargar portfolio</Text>
        <X
          onClick={onCrossIconClick}
          size={px2rem(24)}
          color="var(--slate800)"
          className={styles.crossHeaderIcon}
        />
      </div>
      <div className={styles.downloadReportsBanner}>
        <div className={styles.bannerIcon}>
          <BookUser size={px2rem(24)} color="var(--blue900)" />
        </div>
        <Text variant={TextVariants.SemiboldTextS}>
          En este reporte vas a poder ver cómo estaba compuesto tu portfolio a
          la fecha que selecciones.
        </Text>
      </div>
      <Calendar
        onChange={onCalendarChange}
        date={selectedDate}
        className={styles.reportCalendar}
      />
      <FilledButton onClick={onDownloadReportClick} disabled={isLoading}>
        Descargar
      </FilledButton>
      {isDownloadError && (
        <div className={styles.toastWrapper}>
          <Toast
            message="Hubo un error al descargar tu portfolio valorizado. Por favor, intente de nuevo."
            variant={ToastVariants.Error}
          />
        </div>
      )}
    </div>
  );
};

export default DownloadPortfolioReport;
