import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import * as d3 from "d3";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ThemeVariants } from "interfaces/theme";
import type { BalanceCurrencies } from "interfaces/wallet";
import { getColor } from "utils/getColor";
import { useAppState } from "context/AppStateProvider";
import type { ChartDataPoint } from "interfaces/charts";

import TimeFrameTabs from "./TimeFrameTabs";

import styles from "./styles.module.scss";

type LineChart = d3.Selection<SVGSVGElement | null, unknown, null, undefined>;

interface LineChartProps {
  color: string;
  currency?: BalanceCurrencies;
  selectedTime: number;
  isPerformance?: boolean;
  chartData: ChartDataPoint[];
  onChangeTime: (v: number) => void;
  timeOptions: { label: string; value: number }[];
  onCrosshairChange: (dataPoint?: ChartDataPoint) => void;
  variant?: ThemeVariants;
}

const LineChart: React.FC<LineChartProps> = ({
  currency,
  color,
  chartData,
  timeOptions,
  selectedTime,
  onChangeTime,
  isPerformance,
  onCrosshairChange,
  variant = ThemeVariants.Capital,
}) => {
  const { isMobile } = useDeviceCheck();
  const { isBalanceHidden } = useAppState();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const isOneDay = selectedTime === 0;

  const CHART_WIDTH = isOneDay ? window.screen.width - 5 : window.screen.width;
  const CHART_HEIGHT = isMobile ? 144 : 500;
  const CHART_RIGHT_MARGIN = isMobile ? 30 : 100;
  const CHART_VALUES_RIGHT_MARGIN = isMobile ? 5 : 30;

  const hoverCircleSize = isMobile ? 5 : 20;
  const hoverLineWidth = isMobile ? 1 : 3;
  const hoverLineStroke = isMobile ? 5 : 15;

  const values = chartData.map((item) => item.value);

  const isArs = currency === "ARS";
  const selectedCurrency = isArs ? "$" : "US$";

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const averageValue = (maxValue + minValue) / 2;
  const formatAxisY = isOneDay ? ".4s" : ".3s";

  const formatCurrency = (value: number): string => {
    if (value >= 1e6) {
      return `${selectedCurrency} ${Math.floor(value / 1e5) / 10} M`;
    } else if (value >= 10e3) {
      return `${selectedCurrency} ${Math.floor(value / 1e3)} mil`;
    } else {
      const formattedArsValue =
        value >= 1000 ? (value / 1000).toFixed(3) : Math.floor(value);

      const formatValue = isArs ? formattedArsValue : value.toFixed(2);

      return `${selectedCurrency} ${formatValue}`;
    }
  };

  useEffect(() => {
    const removeOldContent = () => {
      d3.select(svgRef.current).selectAll("*").remove();
    };

    const xAxis = d3
      .scaleUtc()
      .domain(d3.extent(chartData, (d) => new Date(d.date)) as [Date, Date])
      .range([0, CHART_WIDTH - CHART_RIGHT_MARGIN - CHART_VALUES_RIGHT_MARGIN]);

    const yAxis = d3
      .scaleLinear()
      .domain([
        d3.min(chartData, (d) => d.value) as number,
        d3.max(chartData, (d) => d.value) as number,
      ])
      .range([CHART_HEIGHT - 5, 5]);

    const line = d3
      .line<{ date: string; value: number }>()
      .x((d) => xAxis(new Date(d.date)))
      .y((d) => yAxis(d.value))
      .curve(d3.curveBumpX);

    const getLineChart = () => {
      return d3
        .select(svgRef.current)
        .attr("width", CHART_WIDTH)
        .attr("height", CHART_HEIGHT)
        .attr("viewBox", `0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`)
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .attr(
          "class",
          classNames(styles.svg, {
            [styles.crypto]: variant === ThemeVariants.Crypto,
          })
        );
    };

    const setYAxis = () => {
      lineChart
        .append("g")
        .attr("transform", `translate(0,0)`)
        .call(
          d3
            .axisRight(yAxis)
            .tickSizeInner(0)
            .tickValues([minValue, averageValue, maxValue])
            .tickFormat(d3.format(formatAxisY))
        )
        .attr("transform", `translate(${CHART_WIDTH - CHART_RIGHT_MARGIN}, 0)`)
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("x2", -CHART_WIDTH)
            .attr("stroke-width", 1)
            .attr("stroke", "var(--borderColor)")
        )
        .call((g) => {
          g.selectAll(".tick text").each(function (d) {
            const textElement = d3.select(this);
            const price = formatCurrency(d as number);
            const hiddenValues = isMobile ? "● ● ●" : "● ● ● ●";

            textElement.text(null);

            textElement
              .append("tspan")
              .attr("class", styles.yAxisTextSpanPrice)
              .text(price);

            textElement
              .append("tspan")
              .attr("class", styles.yAxisTextSpan)
              .text(hiddenValues);
          });
        });
    };

    const drawChartLine = () => {
      lineChart
        .append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0)
        .attr("y1", yAxis(maxValue))
        .attr("x2", 0)
        .attr("y2", yAxis(0))
        .selectAll("stop")
        .data([
          { offset: "100%", color: getChartColor(0) },
          { offset: "100%", color: getChartColor(-1) },
        ])
        .enter()
        .append("stop")
        .attr("offset", (dataPoint) => {
          return dataPoint.offset;
        })
        .attr("stopColor", (dataPoint) => {
          return dataPoint.color;
        });

      lineChart
        .append("path")
        .attr("d", line(chartData))
        .attr("class", styles.line);
    };

    const getChartColor = (dataPointVaue?: number) => {
      const referencePoint =
        dataPointVaue ?? chartData[chartData.length - 1].value;
      if (!isPerformance) return color;

      if (referencePoint >= 0) return getColor("--green800");

      return getColor("--red800");
    };

    const drawLastDataAnimationCircle = () => {
      const lastDataPoint = chartData[chartData.length - 1];

      lineChart
        .append("circle")
        .attr("cx", xAxis(new Date(lastDataPoint.date)))
        .attr("cy", yAxis(lastDataPoint.value))
        .attr("r", isMobile ? 5 : 20)
        .attr("fill", getChartColor());

      lineChart
        .append("circle")
        .attr("cx", xAxis(new Date(lastDataPoint.date)))
        .attr("cy", yAxis(lastDataPoint.value))
        .attr("r", isMobile ? 5 : 25)
        .attr("fill", getChartColor())
        .attr("fill-opacity", 0)
        .attr("stroke", getChartColor())
        .attr("class", styles.pulseCircle);
    };

    const addHoverLine = (lineChart: LineChart, dataPoint: ChartDataPoint) => {
      lineChart
        .append("line")
        .attr("class", "hover-line")
        .attr("class", "hover-line")
        .attr("x1", xAxis(new Date(dataPoint.date)))
        .attr("x2", xAxis(new Date(dataPoint.date)))
        .attr("y1", 0)
        .attr("y2", CHART_HEIGHT)
        .attr("stroke", getColor("--slate800"))
        .attr("stroke-width", hoverLineWidth)
        .attr("stroke-dasharray", hoverLineStroke)
        .style("pointer-events", "none");
    };

    const addHoverCircle = (
      lineChart: LineChart,
      dataPoint: ChartDataPoint
    ) => {
      lineChart
        .append("circle")
        .attr("class", "hover-circle")
        .attr("cx", xAxis(new Date(dataPoint.date)))
        .attr("cy", yAxis(dataPoint.value))
        .attr("r", hoverCircleSize)
        .attr("fill", getChartColor(dataPoint.value))
        .style("pointer-events", "none");
    };

    const removeData = () => {
      onCrosshairChange();
    };

    const removeHoverCircleAndLine = () => {
      lineChart.selectAll(".hover-line").remove();
      lineChart.selectAll(".hover-circle").remove();
    };

    const onHover = (dataPoint: ChartDataPoint) => {
      removeHoverCircleAndLine();
      addHoverLine(lineChart, dataPoint);
      addHoverCircle(lineChart, dataPoint);
      onCrosshairChange(dataPoint);
    };

    const addInvertactivity = () => {
      const widthForEachLine =
        (CHART_WIDTH - CHART_RIGHT_MARGIN) / chartData.length;

      lineChart
        .selectAll("line.hover-line")
        .data(chartData)
        .enter()
        .append("line")
        .attr("class", "hover-invisible-line")
        .attr("x1", (d) => xAxis(new Date(d.date)))
        .attr("x2", (d) => xAxis(new Date(d.date)))
        .attr("y1", 0)
        .attr("y2", CHART_HEIGHT)
        .attr("stroke", "transparent")
        .attr("stroke-width", widthForEachLine)
        .on("mouseover", (event, dataPoint) => {
          onHover(dataPoint);
        })

        .on("mouseout", () => {
          removeData();
          removeHoverCircleAndLine();
        })

        .on("touchstart", (event) => {
          event.preventDefault();
          onTouchMove(event);
        })
        .on("touchmove", (event) => {
          event.preventDefault();
          onTouchMove(event);
        })
        .on("touchend", () => {
          removeHoverCircleAndLine();
          removeData();
        });

      const onTouchMove = (event: TouchEvent) => {
        const touch = event.touches[0];
        const touchX = touch.clientX;

        const closestData = chartData.reduce((a, b) => {
          return Math.abs(xAxis(new Date(a.date)) - touchX) <
            Math.abs(xAxis(new Date(b.date)) - touchX)
            ? a
            : b;
        });
        removeHoverCircleAndLine();
        onHover(closestData);
      };
    };

    removeOldContent();
    const lineChart = getLineChart();
    setYAxis();
    drawChartLine();
    drawLastDataAnimationCircle();
    addInvertactivity();
  }, [selectedTime, currency]);

  return (
    <div className={classNames({ [styles.hideBalances]: isBalanceHidden })}>
      <svg ref={svgRef} />
      <TimeFrameTabs
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        onClickOption={(v: number) => onChangeTime(v)}
      />
    </div>
  );
};

export default LineChart;
