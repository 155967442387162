import { Cryptos } from "constants/cryptos";
import { CocosFunds } from "constants/instruments";

import CocosBrand from "assets/icons/CocosBrand";
import USFlag from "assets/icons/USFlag";
import ARGFlag from "assets/icons/ARGFlag";
import BRFlag from "assets/icons/BRFlag";
import USDT from "assets/icons/USDT";
import USDC from "assets/icons/USDC";
import DAI from "assets/icons/DAI";
import {
  PaymentCurrencyDetails,
  PaymentTypes,
} from "interfaces/card/interface";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import { Currencies } from "interfaces/wallet";

export const getPaymentMethodsAvailable = (
  paymentCurrency: PaymentCurrencies
) => {
  const paymentCurrencies: Record<PaymentCurrencies, PaymentCurrencyDetails> = {
    [Currencies.ARS]: {
      type: PaymentTypes.CURRENCY,
      name: "Peso Argentino",
      icon: ARGFlag,
      ticker: Currencies.ARS,
    },
    [Currencies.USD]: {
      type: PaymentTypes.CURRENCY,
      name: "Dólar MEP",
      icon: USFlag,
      ticker: Currencies.USD,
    },
    [CocosFunds.AHORRO]: {
      type: PaymentTypes.FCI,
      name: "Cocos Ahorro",
      icon: () => <CocosBrand color={getFCIIconColor(CocosFunds.AHORRO)} />,
      ticker: CocosFunds.AHORRO,
    },
    [CocosFunds.RMA]: {
      type: PaymentTypes.FCI,
      name: "Cocos Daruma RM",
      icon: () => <CocosBrand color={getFCIIconColor(CocosFunds.RMA)} />,
      ticker: CocosFunds.RMA,
    },
    [CocosFunds.USD]: {
      type: PaymentTypes.FCI,
      name: "Cocos Ahorro Dólares",
      icon: () => <CocosBrand color={getFCIIconColor(CocosFunds.USD)} />,
      ticker: CocosFunds.USD,
    },
    [CocosFunds.USD_PLUS]: {
      type: PaymentTypes.FCI,
      name: "Cocos Ahorro Dólares Plus",
      icon: () => <CocosBrand color={getFCIIconColor(CocosFunds.USD)} />,
      ticker: CocosFunds.USD,
    },
    [Cryptos.USDT]: {
      type: PaymentTypes.CRYPTO,
      name: "Tether",
      icon: USDT,
      ticker: Cryptos.USDT,
    },
    [Cryptos.USDC]: {
      type: PaymentTypes.CRYPTO,
      name: "USD Coin",
      icon: USDC,
      ticker: Cryptos.USDC,
    },
    [Cryptos.DAI]: {
      type: PaymentTypes.CRYPTO,
      name: "Dai",
      icon: DAI,
      ticker: Cryptos.DAI,
    },
    STABLECOIN: {
      type: PaymentTypes.CRYPTO,
      name: "Dai",
      icon: DAI,
      ticker: Cryptos.DAI,
    },
    [Currencies.BRL]: {
      type: PaymentTypes.CURRENCY,
      name: "Real Brasilero",
      icon: BRFlag,
      ticker: Cryptos.DAI,
    },
  };

  return paymentCurrencies[paymentCurrency];
};
