import { RiskFire } from "assets/icons/RiskFire";
import { RiskFireOff } from "assets/icons/RiskFire/RiskFireOff";
import px2rem from "utils/px2rem";

interface RiskFireIconsProps {
  risk: number;
  length?: number;
}

export const RiskFireIcons = ({ risk, length = 3 }: RiskFireIconsProps) => (
  <div style={{ display: "flex", gap: px2rem(2) }}>
    {Array.from({ length }, (_, i) => {
      const Icon = i < risk ? RiskFire : RiskFireOff;
      return <Icon key={i} />;
    })}
  </div>
);
