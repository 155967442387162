export enum DateRange {
  ONE_HOUR,
  ONE_DAY,
  ONE_WEEK,
  FIFTEEN_DAYS,
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  MAX,
}
