import type { CocosFund } from "components/page-capital-funds/helpers";

import FundHeading from "../components/FundHeading";
import AnnualReturns from "./AnnualReturns";
import GoalsBox from "./GoalsBox";
import { InfoBox } from "./InfoBox";
import ValueLineChart from "./ValueLineChart";
import FundComposition from "./FundComposition";

interface InformationTabProps {
  selectedFund: CocosFund;
}

const InformationTab = ({ selectedFund }: InformationTabProps) => {
  const { name, ticker, currency } = selectedFund;
  const tna = 0.06;

  return (
    <div>
      <FundHeading name={name} ticker={ticker} />
      <AnnualReturns tna={tna} />
      <ValueLineChart currency={currency} />
      <InfoBox currency={currency} />
      <FundComposition />
      <GoalsBox />
    </div>
  );
};

export default InformationTab;
