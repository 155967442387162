import classNames from "classnames";
import { UIModalSlider } from "components/common/modal-slider";
import getPlatform from "utils/getPlatform";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import { RiskFireIcons } from "../RiskFireIcons";

import styles from "./styles.module.scss";

interface RiskBottomsheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const RISK_INFO = [
  {
    title: "Bajo",
    info: "Retornos estables con un nivel de volatilidad mínimo.",
  },
  {
    title: "Medio",
    info: "Retornos moderados con un nivel de volatilidad controlado.",
  },
  {
    title: "Alto",
    info: "Retornos altos con un nivel de volatilidad superior.",
  },
];

export const RiskBottomsheet = ({ isOpen, onClose }: RiskBottomsheetProps) => {
  const { isIOS } = getPlatform();
  const { isDesktop } = useDeviceCheck();

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClose}
      classes={classNames({
        [styles.isDesktop]: isDesktop,
      })}
    >
      <div
        className={classNames(styles.riskBottomsheetWrapper, {
          [styles.isIOS]: isIOS,
        })}
      >
        <Text
          variant={TextVariants.RegularText}
          color="var(--slate900)"
          className={styles.riskHeading}
        >
          Nivel de Volatilidad
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          El nivel de volatilidad de un fondo refleja la rentabilidad de los
          activos que lo componen y su sensibilidad a los cambios del mercado.{" "}
        </Text>
        <div className={styles.riskInfoWrapper}>
          {RISK_INFO.map(({ title, info }, index) => (
            <div key={title} className={styles.riskRow}>
              <RiskFireIcons risk={index + 1} />
              <div>
                <Text
                  variant={TextVariants.SemiboldTextS}
                  color="var(--slate900)"
                >
                  {title}
                </Text>
                <Text
                  variant={TextVariants.RegularTextS}
                  color="var(--slate800)"
                >
                  {info}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <FilledButton onClick={onClose}>Entendido</FilledButton>
      </div>
    </UIModalSlider>
  );
};
