import { DateRange } from "constants/dateRange";

import { useState } from "react";

import Variation from "components/common/CurrentVariation";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import type { BalanceCurrencies } from "interfaces/wallet";
import Heading, { HeadingVariants } from "components/common/Heading";
import type { ChartDataPoint } from "interfaces/charts";

import { ValueLineChartFCI } from "./ValueLineChartFCI";

import styles from "./styles.module.scss";

interface ValueChartProps {
  currency: BalanceCurrencies;
}

const timeOptions = [
  { label: "15D", value: DateRange.FIFTEEN_DAYS },
  { label: "1M", value: DateRange.ONE_MONTH },
  { label: "3M", value: DateRange.THREE_MONTHS },
  { label: "6M", value: DateRange.SIX_MONTHS },
  { label: "MAX", value: DateRange.MAX },
];

const getVariationText = (period: DateRange): string => {
  if (period === DateRange.ONE_MONTH) return "último mes";
  if (period === DateRange.THREE_MONTHS) return "últimos 3 meses";
  if (period === DateRange.SIX_MONTHS) return "últimos 6 meses";
  if (period === DateRange.ONE_YEAR) return "último año";
  if (period === DateRange.MAX) return "histórico";

  return "últimos 15 días";
};

const mockChartData = [
  {
    date: "2024-10-14",
    value: 1000,
    variation: { amount: 123, percentage: 0.13 },
  },
  {
    date: "2024-10-15",
    value: 1227,
    variation: { amount: 12113, percentage: 0.5 },
  },
  {
    date: "2024-10-16",
    value: 1226,
    variation: { amount: 14423, percentage: 0.223 },
  },
  {
    date: "2024-10-17",
    value: 1243,
    variation: { amount: 1523, percentage: 0.56 },
  },
  {
    date: "2024-10-18",
    value: 1234,
    variation: { amount: 112323, percentage: 0.39 },
  },
];

const ValueLineChart = ({ currency }: ValueChartProps) => {
  const initialChartData = {
    price: 666,
    percentage: 0.22 || 0,
    date: getVariationText(DateRange.FIFTEEN_DAYS),
  };
  const [chartData, setChartData] = useState(initialChartData);
  const [selectedPeriod, setSelectedPeriod] = useState<DateRange>(
    DateRange.FIFTEEN_DAYS
  );

  const onChartDataChange = (data?: ChartDataPoint) => {
    if (!data?.variation) {
      return setChartData(initialChartData);
    }

    setChartData({
      price: data.variation.amount,
      percentage: data.variation.percentage,
      date: data.date,
    });
  };

  const handleOnChangeTime = (value: number) => {
    // TODO: make api call
    setSelectedPeriod(value);
  };

  return (
    <div>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate800)"
      >
        Valor cuotaparte
      </Heading>
      <div className={styles.priceHeading}>
        <PriceLabel
          size={PriceLabelSizes.Medium}
          price={chartData.price}
          currency={currency}
        />
        <Variation
          currency={currency}
          value={chartData.percentage}
          text={chartData.date}
        />
      </div>
      <ValueLineChartFCI
        selectedTime={selectedPeriod}
        onChangeTime={handleOnChangeTime}
        chartData={mockChartData}
        timeOptions={timeOptions}
        color="var(--blue800)"
        onCrosshairChange={onChartDataChange}
      />
    </div>
  );
};

export default ValueLineChart;
