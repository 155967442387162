import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { formatPercentage } from "utils";

import { arcChartColors as colors } from "./ArcChart";
import ArcChart from "./ArcChart";

import styles from "./styles.module.scss";

interface ArcChartData {
  name: string;
  value: number;
}

const mockData: ArcChartData[] = [
  { name: "COCORMA", value: 0.57 },
  { name: "PBR", value: 0.2 },
  { name: "APPL", value: 0.123 },
  { name: "RENDER", value: 0.1423 },
  { name: "Cta Cte $ Rem Bco Comafi", value: 0.2 },
  { name: "Cta Cte $ Rem Bco test", value: 0.523 },
  { name: "Cta Cte $ Rem Bco wqe", value: 0.4 },
  { name: "Cta Cte $ Rem Bco ts", value: 0.31 },
  { name: "Cta Cte $ Bco Comafi", value: 0.22 },
  { name: "Cta Cte $ Rem Bco Comafi", value: 0.5 },
  { name: "Cta Cte $ Rem Bco qwe", value: 0.1 },
];

const FundComposition = () => {
  const charData = mockData.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  return (
    <div className={styles.fundCompositionWrapper}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate800)"
      >
        Composición de Cartera
      </Heading>
      <div className={styles.chartContainer}>
        <ArcChart data={mockData} />
      </div>
      <div className={styles.chartDetailWrapper}>
        {charData.map(({ name, value, color }) => (
          <div key={name} className={styles.chartDetail}>
            <div>
              <div
                style={{ background: color }}
                className={styles.chartColorIcon}
              />
            </div>
            <div>
              <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
                {name}
              </Text>
              <Text
                variant={TextVariants.SemiboldTextS}
                color="var(--slate900)"
              >
                {formatPercentage(value, false)}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FundComposition;
