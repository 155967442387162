import { useEffect, useState } from "react";

import { useMovements } from "context/MovementsProvider";
import { MovementsTypes } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";
import CommonLayout from "layouts/common";
import DrawerLayout from "layouts/drawer";
import { openRightModal } from "features/rightModal/rightModalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import {
  OverlappedButtonsTypes,
  UIOverlappedButtons,
} from "components/common/overlapped-buttons";
import { trackAction } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";
import { FileDown, Info } from "lucide-react";
import px2rem from "utils/px2rem";
import { downloadMovementsReport } from "components/page-movements/utils";
import dayjs from "dayjs";
import { downloadBlob } from "utils/downloadBlob";
import { Tooltip } from "@mui/material";
import Toast, { ToastVariants } from "components/common/Toast";

import MovementsFilter from "../movements-common/movements-filter";
import MovementsInstrument from "../movements-common/movements-instrument";
import MovementsMonetary from "../movements-common/movements-monetary";

import styles from "./styles.module.scss";

const downloadIconSize = px2rem(16);

const MovementsDesktop = () => {
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const currentScreen = useAppSelector(getCurrentScreen);
  const movementTypes = [
    {
      label: "Monetarios",
      value: MovementsTypes.MONETARY,
    },
    {
      label: "Instrumentos",
      value: MovementsTypes.INSTRUMENT,
    },
  ];

  const {
    section,
    filters,
    setSection,
    cashMovementsData,
    handleClearFilters,
    tickersMovementsData,
    getCashMovementsData,
    getTickerMovementsData,
  } = useMovements();

  const [filter, setFilter] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);

  const handleSection = (value: string) => {
    handleClearFilters();
    setSection(value);
    if (value === MovementsTypes.MONETARY) {
      getCashMovementsData(Currencies.ARS);
      return;
    }
    getTickerMovementsData();
  };

  const handleFilter = (value: boolean) => {
    trackAction(`${currentScreen} - ${value ? "Open" : "Close"} filters`);
    setFilter(value);
  };

  const handleOnClose = () => {
    handleFilter(false);
  };

  useEffect(() => {
    if (filter) {
      openRightModalContent(
        <DrawerLayout classes="movements-filters" withHeader={false}>
          <MovementsFilter handleFilter={handleFilter} />
        </DrawerLayout>
      );
      dispatch(
        openRightModal({
          onClose: handleOnClose,
          withCloseButton: false,
        })
      );
    }
  }, [filter]);

  const isMonetary = section === MovementsTypes.MONETARY;
  const shouldHideDownloadButton = filters.currency === Currencies.EXT_ESP;

  const onClickDownload = async () => {
    try {
      const data = await downloadMovementsReport({
        currency: filters.currency,
      });

      const blob = new Blob(["\ufeff" + data], {
        type: "text/csv;charset=utf-8;",
      });

      const url = window.URL.createObjectURL(blob);
      const fileName = `movements_report_${dayjs().format("YYYY-MM-DD")}.csv`;

      downloadBlob(url, fileName);
    } catch (error) {
      setDownloadError(true);
    }
  };

  return (
    <div className={styles.desktopPage}>
      <CommonLayout withHeader={false}>
        <div className={styles.header}>
          <UIOverlappedButtons
            options={movementTypes}
            selectedValue={section}
            type={OverlappedButtonsTypes.Primary}
            onChangeValue={handleSection}
          />
          {!shouldHideDownloadButton && (
            <div className={styles.reportWrapper}>
              <Tooltip title="Descarga movimientos de los últimos 30 días en la moneda que hayas seleccionado.">
                <Info
                  size={px2rem(16)}
                  color="var(--slate900)"
                  className={styles.icon}
                />
              </Tooltip>
              <FilledButton
                onClick={onClickDownload}
                variant={FilledButtonVariants.Download}
              >
                Descargar movimientos <FileDown size={downloadIconSize} />
              </FilledButton>
            </div>
          )}
        </div>
        {isMonetary && (
          <MovementsMonetary
            cashMovements={cashMovementsData}
            onClickFilter={handleFilter}
          />
        )}
        {!isMonetary && (
          <MovementsInstrument
            tickerMovements={tickersMovementsData}
            onClickFilter={handleFilter}
          />
        )}
        {downloadError && (
          <div className={styles.toastWrapper}>
            <Toast
              message="Hubo un error al descargar tu portfolio valorizado. Por favor, intente de nuevo."
              variant={ToastVariants.Error}
              onClose={() => setDownloadError(false)}
            />
          </div>
        )}
      </CommonLayout>
    </div>
  );
};

export default MovementsDesktop;
