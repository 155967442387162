import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Info } from "lucide-react";
import IconBadge from "components/common/Badges/IconBadge";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const GoalsBox = () => {
  // TODO: handle this with api data
  const url = "https://cocos.capital/fondos/cocos-ahorro";

  const getUrlName = (url: string) => {
    return url.replace("https://", "");
  };

  const urlText = `${getUrlName(url)}.`;

  return (
    <div className={styles.goalsWrapper}>
      <Heading
        component="h2"
        variant={HeadingVariants.SemiboldValue}
        color="var(--slate800)"
      >
        Objetivo del fondo
      </Heading>
      <Text
        variant={TextVariants.SemiboldTextS}
        color="var(--slate800)"
        className={styles.goalsText}
      >
        Brindar una alternativa de inversión conservadora que genera
        rendimientos diarios con liquidez inmediata y a muy bajo riesgo de
        mercado.
      </Text>
      <div className={styles.infoBanner}>
        <IconBadge
          isSmall
          backgroundColor="var(--blue800)"
          className={styles.iconBadge}
        >
          <Info color="var(--white-to-darkest)" size={px2rem(14)} />
        </IconBadge>
        <div>
          <Text variant={TextVariants.SemiboldTextS}>
            Conocé más sobre el fondo en:
          </Text>
          <Text variant={TextVariants.SemiboldTextS} color="var(--blue800)">
            <a href={url} target="_blank" rel="noreferrer">
              {urlText}
            </a>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default GoalsBox;
