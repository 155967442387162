interface RiskFireOffProps {
  width?: number;
  height?: number;
}

export const RiskFireOff = ({ width = 13, height = 16 }: RiskFireOffProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.8951 7.26316C3.31386 7.26316 3.71547 7.0968 4.01158 6.80069C4.30769 6.50458 4.47404 6.10297 4.47404 5.68421C4.47404 4.81263 4.15825 4.42105 3.84246 3.78947C3.16541 2.436 3.70099 1.22905 5.10562 0C5.42141 1.57895 6.36878 3.09474 7.63194 4.10526C8.8951 5.11579 9.52668 6.31579 9.52668 7.57895C9.52668 8.15953 9.41232 8.73442 9.19014 9.27081C8.96796 9.8072 8.64231 10.2946 8.23178 10.7051C7.82125 11.1156 7.33387 11.4413 6.79749 11.6635C6.2611 11.8856 5.6862 12 5.10562 12C4.52504 12 3.95015 11.8856 3.41376 11.6635C2.87737 11.4413 2.39 11.1156 1.97947 10.7051C1.56893 10.2946 1.24328 9.8072 1.0211 9.27081C0.798924 8.73442 0.68457 8.15953 0.68457 7.57895C0.68457 6.85074 0.958044 6.1301 1.31615 5.68421C1.31615 6.10297 1.4825 6.50458 1.77861 6.80069C2.07472 7.0968 2.47633 7.26316 2.8951 7.26316Z"
      fill="var(--blue300)"
    />
  </svg>
);
