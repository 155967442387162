import { Haptics } from "@capacitor/haptics";

export const hapticsImpact = async () => {
  try {
    await Haptics.impact();
  } catch (err) {
    console.error("Error on hapticsImpact: ", err);
  }
};

export const hapticsVibrate = async () => {
  try {
    await Haptics.vibrate();
  } catch (err) {
    console.error("Error on hapticsVibrate: ", err);
  }
};
