import { Cryptos } from "constants/cryptos";
import { CocosFunds } from "constants/instruments";

import ARGFlag from "assets/icons/ARGFlag";
import BRFlag from "assets/icons/BRFlag";
import CocosBrand from "assets/icons/CocosBrand";
import DAIIcon from "assets/icons/DAI";
import USDCIcon from "assets/icons/USDC";
import USDTIcon from "assets/icons/USDT";
import USFlag from "assets/icons/USFlag";
import { PayError, PaymentCurrencies } from "interfaces/pay/enums";
import { Currencies } from "interfaces/wallet";

export const isUSD = (currency: PaymentCurrencies) => {
  const currencyArray: PaymentCurrencies[] = [Currencies.USD, CocosFunds.USD];
  return currencyArray.includes(currency);
};

export const isCrypto = (currency: PaymentCurrencies | Currencies) => {
  const cryptoArray: (PaymentCurrencies | Currencies)[] = [
    Cryptos.DAI,
    Cryptos.USDC,
    Cryptos.USDT,
  ];
  return cryptoArray.includes(currency);
};

const currencyIcons = {
  [Currencies.USD]: USFlag,
  [Currencies.ARS]: ARGFlag,
  [Currencies.BRL]: BRFlag,
  [Cryptos.USDC]: USDCIcon,
  [Cryptos.USDT]: USDTIcon,
  [Cryptos.DAI]: DAIIcon,
  [CocosFunds.AHORRO]: CocosBrand,
  [CocosFunds.USD]: CocosBrand,
  [CocosFunds.USD_PLUS]: CocosBrand,
  [CocosFunds.RMA]: CocosBrand,
  STABLECOIN: CocosBrand,
};

export const getIcon = (currency: PaymentCurrencies) => {
  return currencyIcons[currency] || CocosBrand;
};

export const getFCIIconColor = (currency: PaymentCurrencies) => {
  if (currency === CocosFunds.USD) return "#3DD68C";

  return "#70B8FF";
};

export const getMessageError = (error?: PayError) => {
  if (error === PayError.ALREADY_PAID)
    return "Registramos que este pago ya fue aprobado. Avisale al cajero para evitar pagos duplicados.";
  if (error === PayError.INVALID_QR)
    return "Este tipo de QR no es válido para pagar con Cocos Pay.";
  if (error === PayError.PERMISSIONS_DENIED)
    return "Se han rechazado los permisos para acceder a la cámara.";
  if (error === PayError.UNSUPPORTED_DEVICE)
    return "Este dispositivo no está soportado para esta operación.";

  return "No pudimos escanear el código QR correctamente. Por favor, volvé a intentarlo más tarde.";
};

export const getPaymentCurrencyLabel = (paymentCurrency: PaymentCurrencies) => {
  const paymentCurrencyLabels = {
    [Currencies.ARS]: "Pesos",
    [Currencies.USD]: "Dólar MEP",
    [Currencies.BRL]: "Reales",
    [CocosFunds.AHORRO]: "COCOA",
    [CocosFunds.USD]: "COCOAUSD",
    [CocosFunds.USD_PLUS]: "COCOAUSD PLUS",
    [CocosFunds.RMA]: "COCORMA",
    [Cryptos.USDC]: "USDC",
    [Cryptos.USDT]: "USDT",
    [Cryptos.DAI]: "DAI",
    STABLECOIN: "Dólar crypto",
  };

  return paymentCurrencyLabels[paymentCurrency] || "";
};

export const getPaymentCurrencySymbol = (
  paymentCurrency: PaymentCurrencies
) => {
  if (isUSD(paymentCurrency)) return "US$";

  if (isCrypto(paymentCurrency)) return paymentCurrency;

  return "$";
};

export const getPayBalance = (
  currency: PaymentCurrencies,
  amountAvailable: number,
  amountAvailableArs: number,
  amountAvailableUsd?: number
) => {
  const isUsdCurrency = isUSD(currency);
  const isCryptoCurrency = isCrypto(currency);

  if (isUsdCurrency) return amountAvailableUsd || 0;

  if (isCryptoCurrency) return amountAvailable;

  return amountAvailableArs;
};

export const getPaymentCurrency = (currency: PaymentCurrencies) => {
  const isUSDCurrency = isUSD(currency);

  const currencyArray: PaymentCurrencies[] = [
    Currencies.ARS,
    Currencies.USD,
    Cryptos.USDC,
    Cryptos.USDT,
    Cryptos.DAI,
  ];

  const isCurrency = currencyArray.includes(currency);

  if (isUSDCurrency) return Currencies.USD;

  if (isCurrency) return currency;

  return Currencies.ARS;
};

export const MIN_USD_TO_PAY = 1;

export const MIN_BRL_TO_PAY = 5;

export const MAX_DEBT = 10000;
