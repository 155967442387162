import { getFCIIconColor } from "components/page-cocos-pay/utils";
import px2rem from "utils/px2rem";
import CocosBrand from "assets/icons/CocosBrand";
import Heading, { HeadingVariants } from "components/common/Heading";
import type { PaymentCurrencies } from "interfaces/pay/enums";

import styles from "./styles.module.scss";

interface FundHeadingProps {
  name: string;
  ticker: PaymentCurrencies;
}

const FundHeading = ({ name, ticker }: FundHeadingProps) => {
  return (
    <div className={styles.infoHeading}>
      <CocosBrand color={getFCIIconColor(ticker)} size={px2rem(32)} />
      <Heading
        component="h2"
        variant={HeadingVariants.RegularTitle}
        color="var(--slate800)"
      >
        {name}
      </Heading>
    </div>
  );
};

export default FundHeading;
