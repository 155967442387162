import API from "apis";
import { movementsService } from "apis/services";
import dayjs from "dayjs";
import { CurrencyCodes, CurrencyValues } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";

export const getInstrumentName = (code?: string) => {
  if (!code) return null;

  if (code === CurrencyCodes.PESOS) return CurrencyValues.ARS;

  if (code === CurrencyCodes.DOLARES) return CurrencyValues.USD;

  return code;
};

export const downloadMovementsReport = async ({
  currency,
}: {
  currency: Currencies;
}) => {
  const dateTo = dayjs().format("YYYY-MM-DD");
  const dateFrom = dayjs().subtract(30, "day").format("YYYY-MM-DD");
  const formatFile = "CSV";
  const dateProperty = "settlement";

  const { data } = await API.get<Blob>(movementsService.download, {
    params: {
      dateTo,
      currency,
      dateFrom,
      formatFile,
      dateProperty,
    },
  });

  return data;
};
